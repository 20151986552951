import * as React from "react"

import Seo from "../components/seo"
import Slider from "../components/termsandconditions"

import {Helmet} from "react-helmet";




const mainPage = ({}) => {



  return (

<div>
    <Seo
        title='Terms and Conditions | Smartlink Communications'
        
        description='Terms and Conditions for Smartlink Communications '

        keywords='Terms and Conditions, Smartlink Communications, Smartlink, Radu Magdin, Radu Nicola Delicote'

      />

<Helmet>






</Helmet>


<Slider />
     
    </div>
  )

}


export default mainPage;